/*
 *  Owl Carousel - Core
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
	overflow: hidden;
	//border-radius: 4px;
	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;
		touch-action: manipulation;
		-moz-backface-visibility: hidden; /* fix firefox animation glitch */
	}
	& .owl-image {
		max-width: 100%;
		height: auto;
		display: block;
		margin: 0 auto;
	}
	& .item {
		position: relative;
		display: block;
		color: #fff;
		min-height: 230px;
		background-position: center top;
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		& .owl-desc {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
			font-weight: 500;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: 0 10px 20px 10px;
			font-size: calc(12px + .4vw);
			& h4 {
				font-size: 16px;
				line-height: 1.31;
				& .owl-comments {
					font-size: 12px;
					display: inline-flex;
					align-items: center;
				}
			}

			& .icon-comments-white {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='none' fill-rule='nonzero' stroke='%23fff' d='M7.134 9.155L7.41 7.5H9a.5.5 0 0 0 .5-.5V1A.5.5 0 0 0 9 .5H1a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h3.651l2.483 1.655z'/%3E%3C/svg%3E%0A");
				width: 10px;
				height: 10px;
				display: inline-block;
				margin-right: 4px;
			}
		}
	}

	.owl-stage:after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;
		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-wrapper,
	.owl-item{
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		-ms-backface-visibility: hidden;
		-webkit-transform: translate3d(0,0,0);
		-moz-transform: translate3d(0,0,0);
		-ms-transform: translate3d(0,0,0);
	}

	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
	}


	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none;
	}
	.owl-dots {
		position: absolute;
		bottom: 0;
		width: 100%;
		display: flex;
		justify-content: center;
		& .owl-dot  {
			min-width: 30px;
			margin-right: 10px;
			margin-left: 10px;
			padding: 6px 0 !important;
			background: transparent;
			&.active span {
				width: 100%;
				height: 3px;
				background: $primary;
				display: block;
				-webkit-border-radius: 2px;
				-moz-border-radius: 2px;
				border-radius: 2px;
				transition: .2s;
				opacity: 1;
			}
			& span {
				height: 3px;
				background: #fff;
				opacity: .5;
				display: block;
				-webkit-border-radius: 2px;
				-moz-border-radius: 2px;
				border-radius: 2px;
				transition: .2s;

			}

		}

	}
	.owl-nav {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		& .owl-prev, .owl-next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%) rotate(0);
			width: 24px;
			height: 34px;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='34' viewBox='0 0 24 34'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23000' d='M24 0H0v34h24z' opacity='.8'/%3E%3Cpath stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.984 9L8 16.992 16 25'/%3E%3C/g%3E%3C/svg%3E");
		}
		& .owl-next {
			right: 0;
			transform: translateY(-50%) rotate(180deg);
		}
	}

	.owl-nav .owl-prev,
	.owl-nav .owl-next,
	.owl-dot {
		cursor: pointer;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.owl-nav button.owl-prev,
	.owl-nav button.owl-next,
	button.owl-dot {
		//background: none;
		color: inherit;
		border: none;
		padding:0!important;
		font: inherit;
	}

	&.owl-loaded {
		display: block;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
	}

	&.owl-hidden {
		opacity: 0;
	}

	&.owl-refresh .owl-item {
		visibility: hidden;
	}

	&.owl-drag .owl-item {
		touch-action: pan-y;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	&.owl-grab {
		cursor: move;
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;
	}

	&.owl-rtl .owl-item {
		float: right;
	}
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}
