.breadcrumb {
  display: flex;
  align-items: center;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  list-style: none;
  background-color: $breadcrumb-bg;
  overflow-x: auto;
  touch-action: pan-x pan-y;
  margin-left: -10px;
  margin-right: -10px;
  white-space: nowrap;
}

.breadcrumb-item {
  color: #9a9caf;
  font-size: 12px;
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;
    &::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      margin-right: $breadcrumb-item-padding;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7' height='11' viewBox='0 0 7 11'%3E%3Cpath fill='%239a9caf' d='M.158 9.45l3.817-3.825L.158 1.8 1.333.625l5 5-5 5z'/%3E%3C/svg%3E%0A");
      width: 7px;
      height: 11px;
    }
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active, a {
    color: $breadcrumb-active-color;
  }

}
